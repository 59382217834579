import React from "react";
import { renderRoutes } from "react-router-config";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
  Observable,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { Routes } from "./routes";
import storage from "./utils/storage";
import { ToastServiceProvider } from "./context/ToastService";
import firebaseAuth from "./firebaseConfig";

const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL;

// Auth Link
const authLink = setContext((_, { headers }) => {
  const token = storage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Log GraphQL errors
const errorLink = onError((context) => {
  const { graphQLErrors, networkError, operation, forward } = context;
  const { message, locations, path } = graphQLErrors[0];
  if (message.includes("Firebase ID token")) {
    if (!firebaseAuth.currentUser) return;
    return new Observable((observer) => {
      firebaseAuth.currentUser
        .getIdToken(true)
        .then((idToken) => {
          console.log("Refresh token accessed");
          storage.setItem("token", idToken);
          operation.setContext(() => ({
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }));
        })
        .catch((refreshErr) => {
          let message =
            refreshErr?.graphQLErrors?.[0]?.message || "Something went wrong";
          console.log("Refresh token error", message);
          storage.removeItem("token");
        })
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };
          forward(operation).subscribe(subscriber);
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }
  console.log(
    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
  );
  // });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

// GraphQL Link
const link = from([
  authLink,
  errorLink,
  new HttpLink({ uri: GRAPHQL_URL, credentials: "same-origin" }),
]);

// Initialize Apollo Client
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
});

function Root() {
  return (
    <ApolloProvider client={client}>
      <ToastServiceProvider>{renderRoutes(Routes)}</ToastServiceProvider>
    </ApolloProvider>
  );
}

export default Root;
