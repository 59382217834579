import { lazy } from "react";

import App from "./App";
const StarlineRevenueReport = lazy(() =>
  import("./pages/StarlineRevenueReport")
);
const StarlineDailyReportDetailedView = lazy(() =>
  import("./pages/StarlineDailyReportDetailedView")
);
const StarlineDailyReports = lazy(() => import("./pages/StarlineDailyReports"));
const AddStarlineResult = lazy(() => import("./pages/AddStarlineResult"));
const StarlineResultsList = lazy(() => import("./pages/StarlineResultsList"));
const AddStarline = lazy(() => import("./pages/AddStarline"));
const StarlinesList = lazy(() => import("./pages/StarlinesList"));
const WeeklyPattis = lazy(() => import("./pages/WeeklyPatti"));
const WebsiteSections = lazy(() => import("./pages/WebsiteSections"));
const AppSections = lazy(() => import("./pages/AppSections"));
const AddResult = lazy(() => import("./pages/AddResult"));
const AddGame = lazy(() => import("./pages/AddGame"));
const GamesList = lazy(() => import("./pages/GamesList"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Login = lazy(() => import("./pages/Login"));
const Markets = lazy(() => import("./pages/Markets"));
const Results = lazy(() => import("./pages/Results"));
const ResultsList = lazy(() => import("./pages/ResultsList"));
const DailyReports = lazy(() => import("./pages/DailyReports"));
const DailyReportDetailedView = lazy(() =>
  import("./pages/DailyReportDetailedView")
);
const RevenueReports = lazy(() => import("./pages/RevenueReport"));
const GamesUserReports = lazy(() => import("./pages/GamesUserReports"));
const StarlineUserReports = lazy(() => import("./pages/StarlineUserReports"));
const WalletRequests = lazy(() => import("./pages/WalletRequests"));
const AdminDetails = lazy(() => import("./pages/AdminDetails"));
const MyProfile = lazy(() => import("./pages/MyProfile"));
const NotFound = lazy(() => import("./pages/NotFound"));
const AddMarket = lazy(() => import("./pages/AddMarket"));
const Users = lazy(() => import("./pages/Users"));
const User = lazy(() => import("./pages/User"));
const NoticeBoard = lazy(() => import("./pages/NoticeBoard"));
const GameRates = lazy(() => import("./pages/GameRates"));
const StarlineRates = lazy(() => import("./pages/StarlineRates"));
const HowToPlay = lazy(() => import("./pages/HowToPlay"));
const HomeScreenNotification = lazy(() =>
  import("./pages/HomeScreenNotification")
);

export const Routes = [
  {
    path: "/login",
    component: Login,
    exact: true,
  },
  {
    path: "/404",
    component: NotFound,
    exact: true,
  },
  {
    path: "/",
    component: App,
    routes: [
      {
        path: "/",
        component: Dashboard,
        exact: true,
      },
      {
        path: "/games",
        component: GamesList,
        exact: true,
      },
      {
        path: "/games/:urlSlug",
        component: AddGame,
        exact: true,
      },
      {
        path: "/games/:urlSlug/results",
        component: ResultsList,
        exact: true,
      },
      {
        path: "/games/:urlSlug/results/:resultId",
        component: AddResult,
        exact: true,
      },
      {
        path: "/starlines",
        component: StarlinesList,
        exact: true,
      },
      {
        path: "/starlines/:urlSlug",
        component: AddStarline,
        exact: true,
      },
      {
        path: "/starlines/:urlSlug/results",
        component: StarlineResultsList,
        exact: true,
      },
      {
        path: "/starlines/:urlSlug/results/:resultId",
        component: AddStarlineResult,
        exact: true,
      },
      {
        path: "/markets",
        component: Markets,
        exact: true,
      },
      {
        path: "/markets/add",
        component: AddMarket,
      },
      {
        path: "/markets/edit/:id",
        component: AddMarket,
      },
      {
        path: "/results",
        component: Results,
      },
      {
        path: "/games-daily-reports",
        component: DailyReports,
        exact: true,
      },
      {
        path: "/games-user-reports",
        component: GamesUserReports,
        exact: true,
      },
      {
        path: "/starline-user-reports",
        component: StarlineUserReports,
        exact: true,
      },
      {
        path: "/starline-daily-reports",
        component: StarlineDailyReports,
        exact: true,
      },
      {
        path: "/games-daily-reports/:gameId",
        component: DailyReportDetailedView,
      },
      {
        path: "/starline-daily-reports/:starlineId",
        component: StarlineDailyReportDetailedView,
      },
      {
        path: "/games-revenue-reports",
        component: RevenueReports,
        exact: true,
      },
      {
        path: "/starline-revenue-reports",
        component: StarlineRevenueReport,
        exact: true,
      },
      {
        path: "/wallet-requests",
        component: WalletRequests,
      },
      {
        path: "/admin-details",
        component: AdminDetails,
      },
      {
        path: "/profile",
        component: MyProfile,
      },
      {
        path: "/users",
        component: Users,
        exact: true,
      },
      {
        path: "/users/:id",
        component: User,
      },
      {
        path: "/notice-board",
        component: NoticeBoard,
      },
      {
        path: "/game-rates",
        component: GameRates,
      },
      {
        path: "/starline-rates",
        component: StarlineRates,
      },
      {
        path: "/how-to-play",
        component: HowToPlay,
      },
      {
        path: "/home-notice-board",
        component: HomeScreenNotification,
      },
      {
        path: "/app-sections",
        component: AppSections,
      },
      {
        path: "/website-sections",
        component: WebsiteSections,
      },
      {
        path: "/weekly-pattis",
        component: WeeklyPattis,
      },
      {
        component: NotFound,
      },
    ],
  },
];
