import jwtDecode from "jwt-decode";
import { useHistory } from "react-router-dom";

const useAuth = () => {
  const history = useHistory();

  const getToken = () => localStorage.getItem("token");

  const getEmail = () => localStorage.getItem("email");

  const isSubAdmin = () => {
    const email = getEmail();
    if (!email) return false;
    return email.includes("sub");
  };

  const setToken = (token) => {
    localStorage.setItem("token", token);
    const decodedToken = jwtDecode(token);
    localStorage.setItem("email", decodedToken.email);
    history.push("/");
  };

  const logout = () => {
    localStorage.removeItem("token");
    history.push("/login");
  };

  return {
    getToken,
    getEmail,
    isSubAdmin,
    setToken,
    logout,
  };
};

export default useAuth;
