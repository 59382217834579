const colors = {
  primary: "#2A899C",
  primaryLight: "#6843ff26",
  secondary: "#69b99d",
  secondaryLight: "#69b99d26",
  tertiary: "#ff7271",
  tertiaryLight: "#ff727126",
  text: "#29323c",
  black: "#000000",
  white: "#ffffff",
  medium: "#6e6969",
  light: "#f5f5f5",
  dark: "#0c0c0c",
  danger: "#f54748",
  dangerDark: "#da2929",
  border: "#cccccc",
};

export default colors;
