// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyBvFonJniQrAO5FmVTdBAfIwi30v2LczkU",
  authDomain: "milan-kalyan-results.firebaseapp.com",
  projectId: "milan-kalyan-results",
  storageBucket: "milan-kalyan-results.appspot.com",
  messagingSenderId: "923764467914",
  appId: "1:923764467914:web:815d0eceeda0df02f86c33"
  // apiKey: "AIzaSyApv_CT7By_pm7GvUcSI04q7orIVn8t6O0",
  // authDomain: "milan-results.firebaseapp.com",
  // projectId: "milan-results",
  // storageBucket: "milan-results.appspot.com",
  // messagingSenderId: "70791655693",
  // appId: "1:70791655693:web:4dc764c0963149a3de4044",
  // measurementId: "G-QYVBEW5FBY",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const firebaseAuth = getAuth(firebaseApp);
export default firebaseAuth;
