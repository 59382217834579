import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { Logout, LockReset, Home } from "mdi-material-ui";
import useAuth from "../hooks/useAuth";
import colors from "../shared/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  titleBlock: {
    flexGrow: 1,
  },
  title: {
    color: colors.white,
    textDecoration: "none",
  },
  list: {
    width: 250,
  },
}));

function Header() {
  const classes = useStyles();
  const auth = useAuth();

  return (
    <AppBar position="static">
      <Toolbar>
        <Link
          style={{ justifyContent: "left", color: "#FFFFFF" }}
          color="inherit"
          to={`/`}
        >
          <Home style={{ marginRight: 12 }} />
        </Link>
        <div className={classes.titleBlock}>
          <Typography
            component={Link}
            to="/"
            variant="h6"
            className={classes.title}
          >
            Satta Matka
          </Typography>
        </div>
        <div>
          {!auth.isSubAdmin() && (
            <Link to={"/profile"} style={{ color: colors.white }}>
              <IconButton color="inherit">
                <LockReset />
              </IconButton>
            </Link>
          )}
          <IconButton onClick={() => auth.logout()} color="inherit">
            <Logout />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
